import React from 'react'
import Layout from '../components/layout'


const Podcast = ({location}) => {
  return (
    <>
        <Layout location = {location}>
        <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 lg:py-12 overflow-y-hidden z-0 px-3 lg:px-0 podcastbanner">
            <div class="max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-5 justify-center items-center z-10 lg:px-3">
                <div class="w-full flex flex-col text-center lg:text-left justify-center lg:items-start items-center">
                    <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full border border-gray-darklight bg-indigo-50">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png" alt="Vantage Pulse" height="15" width="15" />
                        <h2 class="text-purple-100 text-sm lato"><span class="font-bold">&nbsp;&nbsp;VANTAGE </span>REWARDS</h2>
                    </div>

                    <h1 class="text-purple-100 text-4xl text-center lg:text-left lg:text-7xl">Vantage Influencers' <span className = "text-orange">Podcasts</span></h1>


                    <p class="my-8 text-lg xl:text-xl text-purple-100">Automate and simplify your rewards and recognition program with Vantage Rewards.</p>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-full xl:w-9/12 2xl:w-2/3 cursor-pointer">
                    <a class="vc-new-orange-btn lato" href="#FormVR">Request Demo</a>
                    <button class="vc-ghost-btn-white lato modal-open modal-toggle">Watch Now</button></div>
                </div> 
            
                {/* <div class="w-full opacity-0 lg:hidden justify-center items-center mt-6"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Employee-Recognition-Programs-That-Really-Work-1.png" alt="Vantage Rewards" width="751" height="443" /></div>
                <div class="w-full flex lg:hidden absolute right-0 md:-right-3 bottom-0 justify-center items-center mt-6"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Hero-img-rewards.png" alt="Vantage Rewards" width="751" height="443" /></div> */}
            </div>
            {/* <div class="z-10 lg:flex justify-center lg:justify-end items-center my-9 lg:my-0 absolute lg:-right-40 xl:-right-28 2xl:-right-10 lg:-bottom-16 xl:-bottom-1 hidden transform lg:scale-75 xl:scale-90 2xl:scale-100"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Hero-img-rewards.png" alt="Vantage Rewards" width="751" height="443" /></div> */}
        </section>
        </Layout>
    </>
  )
}

export default Podcast